// Will make a single grid row.
@mixin make-row($breakpoint) {
  .row {
    @include variant($breakpoint + '-no-gutters') {
      margin: 0;
    }

    @include variant($breakpoint + '-top') {
      align-items: flex-start;
    }

    @include variant($breakpoint + '-center') {
      align-items: center;
    }

    @include variant($breakpoint + '-bottom') {
      align-items: flex-end;
    }

    @include variant($breakpoint + '-reverse') {
      flex-direction: row-reverse;
    }
  }
}

// Will make the grid rows.
@mixin make-rows($breakpoints) {
  @each $breakpoint, $width in $breakpoints {
    @if ($width == 0) {
      @include make-row($breakpoint);
    } @else {
      @media (min-width: $width) {
        @include make-row($breakpoint);
      }
    }
  }
}
