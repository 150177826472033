// Will make a single grid.
@mixin make-grid($breakpoint) {
  .grid {
    @include variant($breakpoint + '-no-gutters') {
      margin: 0;
    }
  }
}

// Will make the grids.
@mixin make-grids($breakpoints) {
  @each $breakpoint, $width in $breakpoints {
    @if ($width == 0) {
      @include make-grid($breakpoint);
    } @else {
      @media (min-width: $width) {
        @include make-grid($breakpoint);
      }
    }
  }
}

