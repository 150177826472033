/* ----------------------*/
/* ////// #Buttons //////*/
/* ----------------------*/

.btn {
  @include fluidType(16px, 20px);
  color: $color-grayDk;
  cursor: pointer;
  font: {
    family: $font-style; }
  text: {
    decoration: none;
    transform: uppercase; }
  background: transparent;
  border: 0;
  display: inline-block;
  padding: 9px calc(14px + .5625em) 5px 9px;
  position: relative;
  @include mq($from: md) {
    padding: 13px calc(18px + .5625em) 9px 13px; }
  &--reverse {
    padding: 9px 9px 5px calc(14px + .5625em;
    @include mq($from: md) {
      padding: 13px 13px 9px calc(18px + .5625em); }
    .icon {
      left: 9px;
      @include mq($from: md) {
        left: 13px; } } }
  .icon {
    // margin:
    //   left: 5px
    position: absolute;
    top: calc(50% - .3125em + 1px);
    right: 9px;
    transition: all 450ms 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    @include mq($from: md) {
      right: 13px; }
    // &--arrow
    //   &--reverse
    //     // margin:
    //  //   left: 0
 }    //  // right: 5px
  &:hover, &:focus {
    .icon {
      // color: $color-white
      opacity: .388;
      transform: translateX(-12px) scale(1.75);
      transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
      &--arrow {
        &--reverse {
          transform: translateX(12px) scale(1.75) rotate(180deg); } } } }
  &--ghost {
    color: $color-white;
    span {
      position: relative;
      &::after {
        content: "";
        background: rgba($color-purpleBt, .388);
        bottom: -8px;
        height: 1px;
        left: 0;
        position: absolute;
        width: 0;
        transition: width 400ms cubic-bezier(0.895, 0.030, 0.685, 0.220); } }
    &:hover {
      span {
        &::after {
          width: 100%; } } } }
  &--dk, &--lt {
    &::after, &::before {
      content: "";
      border: 1px solid $color-purpleBt;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: transform 400ms cubic-bezier(0.895, 0.030, 0.685, 0.220), border-color 300ms 200ms ease-in; }
    &:hover {
      &::after, &::before {}
      transition: transform 400ms cubic-bezier(0.230, 1.000, 0.320, 1.000), border-color 300ms 100ms ease-out;
      &::after {
        transform: translate(3px, -4px); }
      &::before {
        transform: translate(-3px, 4px); } } }
  &--dk {
    &::before {
      border: {
        color: $color-purpleDk; } } }
  &--lt {
    color: $color-white;
    &::before {
      border: {
        color: $color-purpleLt; } } } }
