@import '../bootstrap.scss';

.grid {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 (map_get($gutter, 'width') / 2);

  @include variant('container') {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }

  @include variant('no-gutters') {
    padding: 0;
  }
}

@include make-grids($breakpoints);
