/* --------------------*/
/* ////// #Icons //////*/
/* --------------------*/

.icon {
  &--arrow {
    color: $color-purpleBt;
    height: em(10);
    width: em(9);
    &--reverse {
      transform: rotate(180deg); } }
  &--menu {
    height: em(57);
    max: {
      width: 100%; }
    width: em(81); }
  &--fb {
    height: em(33);
    width: em(15.4); }
  &--insta {
    height: em(32.7);
    width: em(33); }
  &--li {
    height: em(32.9);
    width: em(33); } }
