/* ------------------------*/
/* ////// #Slideshow //////*/
/* ------------------------*/

.slide {
  $s: &;
  height: 100%;
  transform: none !important;
  width: auto !important;
  &__track {
    @include vp-relative-prop("padding-bottom", 130px);
    height: 100%;
    width: 100%; }
  &__title {
    opacity: 0;
    transform: translateX(-60px);
    transition: opacity 600ms 800ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 600ms 800ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    span {
      opacity: 0;
      transform: translateX(-80px);
      transition: opacity 600ms 1100ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 600ms 1100ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
    strong {
      opacity: 0;
      transform: translateX(-80px);
      transition: opacity 600ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 600ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000); } }
  @at-root .has-js .header & {
    .btn {
      opacity: 0;
      transition: opacity 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000); } }

  &__li {
    // @include vp-relative-prop('padding-bottom', 105px)
    align: {
      items: flex-start; }
    display: flex;
    flex: {
      direction: column; }
    justify: {
      content: flex-end; }
    left: 0;
    max: {
      width: 100%; }
    padding: {
      bottom: 2px; }
    position: absolute;
    top: 0;
    &--active {
      #{$s}__title {
        &, & strong, & span {
          opacity: 1;
          transform: translateX(0); } }
      @at-root .has-js .header & {
        .btn {
          opacity: 1; } } } } }

.slideNav {
  $s: &;
  display: flex;
  flex: {
    direction: column; }
  margin: {
    top: em(10); }
  &__bullet {
    background: $color-purpleBt;
    border: 2px solid $color-purpleBt;
    cursor: pointer;
    height: em(25);
    margin: em(5);
    opacity: 0;
    width: em(25);
    transition: all 400ms cubic-bezier(0.600, 0.040, 0.980, 0.335);
    @for $i from 1 through 6 {
      &:nth-of-type(#{$i}) {
        transition: {
          delay: 125ms * ($i - 1); } } }
    &:hover {
      border: {
        color: $color-white; } }
    &--active {
      background: $color-white;
      border: {
        color: $color-white; } } }
  &.is-visible {
    #{$s}__bullet {
      opacity: 1; } } }
