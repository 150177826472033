// Will make the column helpers.
@mixin make-column-helpers($breakpoint) {
  .col {
    @include variant($breakpoint + '-no-gutters') {
      padding: 0;
    }
    @include variant($breakpoint + '-align-top') {
      align-self: flex-start;
    }
    @include variant($breakpoint + '-align-center') {
      align-self: center;
    }
    @include variant($breakpoint + '-align-bottom') {
      align-self: flex-end;
    }
    @include variant($breakpoint + '-first') {
      order: -1;
    }
    @include variant($breakpoint + '-last') {
      order: 1;
    }
    @include variant($breakpoint + '-reset') {
      order: 0;
    }
    @include variant($breakpoint + '-flex') {
      display: flex;
    }
  }
}

// Will make the default column.
@mixin make-default-column($breakpoint) {
  .col {
    @include variant($breakpoint) {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
  }
}

// Will make a single column
@mixin make-responsive-column($breakpoint, $column, $width) {
  .col {
    @include variant($breakpoint + '-' + $column) {
      flex: 0 0 ($width);
      max-width: ($width);
    }
  }
}

// Will make the columns.
@mixin make-responsive-columns($columns, $breakpoint) {
  @for $column from 0 through $columns {
    $width: 0;

    @if ($column != 0) {
      $width: percentage(1 / $columns * $column);
    }

    @include make-responsive-column($breakpoint, $column, $width);
  }
}

// Will make a single offset
@mixin make-offset-column($breakpoint, $column, $margin) {
  .col {
    @include variant($breakpoint + '-offset-' + $column) {
      margin-left: ($margin);
    }
  }
}

// Will make the offsets.
@mixin make-offset-columns($columns, $breakpoint) {
  @for $column from 0 through $columns {
    $offset: 0;

    @if ($column != 0) {
      $offset: percentage(1 / $columns * $column);
    }

    @include make-offset-column($breakpoint, $column, $offset);
  }
}

// Will make a single grid column
@mixin make-column($breakpoint, $columns) {
  @include make-column-helpers($breakpoint);
  @include make-default-column($breakpoint);
  @include make-responsive-columns($columns, $breakpoint);
  @include make-offset-columns($columns, $breakpoint);
}

// Will make the grid columns
@mixin make-columns($breakpoints, $columns) {
  @each $breakpoint, $width in $breakpoints {
    @if ($width == 0) {
      @include make-column($breakpoint, $columns);
    } @else {
      @media (min-width: $width) {
        @include make-column($breakpoint, $columns);
      }
    }
  }
}
