/* -------------------------*/
/* ////// #Animations //////*/
/* -------------------------*/

.lazy-img {
  opacity: 0;
  transition: opacity 300ms ease 200ms;
  &.is-loaded {
    opacity: 1; } }

// .has-js .header.will-animate
.has-js .header {
  .logo {
    &__img {
      opacity: 0;
      // transform: translateX(50px)
      transition: all 800ms 0ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
    &::after {
      width: 0;
      transition: all 600ms 800ms cubic-bezier(0.230, 1.000, 0.320, 1.000); } }
  .navbar {
    &__btn {
      opacity: 0;
      transition: all 800ms 0ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
    &__vert {
      max: {
        height: 0; }
      transition: max-height 1200ms 2600ms cubic-bezier(0.230, 1.000, 0.320, 1.000); } }
  .title {
    opacity: 0;
    transform: translateX(-60px);
    // transition: opacity 600ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 800ms, transform 600ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 800ms
    span {
      opacity: 0;
      transform: translateX(-80px);
      transition: opacity 600ms 1100ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 600ms 1100ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
    strong {
      opacity: 0;
      transform: translateX(-80px);
      transition: opacity 600ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 600ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000); } }
  .header__btn {
    color: transparent;
    opacity: 0;
    // transform: translateX(60px)
    // transition: color 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000)
    &::before {
      opacity: 0;
      transform: translate(7px, -7px);
 }      // transition: opacity 100ms 4400ms ease
    &::after {
      opacity: 0;
      transform: translate(-7px, 7px);
 }      // transition: opacity 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000)
    .icon {
      opacity: .2;
      transform: translateX(-30px) scale(2.75);
 } }      // transition: opacity 600ms 3200ms cubic-bezier(.25,.46,.45,.94), transform 600ms 3200ms cubic-bezier(.25,.46,.45,.94)
  &.is-animating, &.is-animated {
    .navbar {
      &__vert {
        max: {
          height: 100%; } } }
    .logo {
      &::after {
        width: calc(100% + 5px); } } }
  &.is-animating {
    .header__btn {
      opacity: 1;
      &::before {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
      &::after {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
      .icon {
        opacity: 1;
        transform: translateX(0) scale(1);
        transition: opacity 600ms 3200ms cubic-bezier(.25,.46,.45,.94), transform 600ms 3200ms cubic-bezier(.25,.46,.45,.94); } } } }

.is-loaded .header {
  &.will-animate, &.is-animated {
    .logo {
      &__img {
        opacity: 1;
        transform: translateX(0); } }
    .navbar {
      &__btn {
        opacity: 1; } } }
  &.is-animated {
    .title {
      transition: opacity 600ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 800ms, transform 600ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 800ms;
      &, & strong, & span {
        opacity: 1;
        transform: translateX(0); } }
    .header__btn {
      color: $color-white;
      opacity: 1;
      transform: translateX(0);
      transition: color 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity 2000ms 1400ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
      &::before {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
      &::after {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000), transform 2200ms 2200ms cubic-bezier(0.230, 1.000, 0.320, 1.000); }
      .icon {
        opacity: 1;
        transform: translateX(0) scale(1);
        transition: opacity 600ms 3200ms cubic-bezier(.25,.46,.45,.94), transform 600ms 3200ms cubic-bezier(.25,.46,.45,.94); } } } }
