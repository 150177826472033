/* -----------------------------*/
/* ////// #Little Helpers //////*/
/* -----------------------------*/

.line {
  background: {
    color: $color-purpleBt; }
  position: absolute; }

.img {
  @include vp-relative-prop("margin-bottom", 18px);
  &--pad {
    @include vp-relative-prop("padding-left", 18px);
    @include vp-relative-prop("padding-right", 18px); }
  &__wrap {
    background: rgba($color-purple, .5);
    &--bar {
      border: {
        bottom: 4px solid $color-purple; }
      @include mq($from: md) {
        border: {
          bottom: {
            width: 6px; } } } }
    &::before {
      content: "";
      background: $color-purple;
      display: block;
      padding: {
        top: 56%; } } } }

.imgplus {
  cursor: pointer;
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    background: $color-purpleBt;
    bottom: -8px;
    height: 24px;
    position: absolute;
    right: -8px;
    width: 24px;
    @include mq($from: md) {
      bottom: -8px;
      height: 34px;
      right: -8px;
      width: 34px; } }
  &__plus {
    bottom: em(-8);
    height: 25px;
    position: absolute;
    right: em(-8);
    width: 25px;
    &::before, &::after {
      content: "";
      background: $color-white;
      position: absolute;
      z: {
        index: 1; } }
    &::before {
      height: calc(100% - 13px);
      left: 50%;
      top: 7px;
      width: 1px; }
    &::after {
      height: 1px;
      left: 7px;
      top: 50%;
      width: calc(100% - 13px); }
    @include mq($from: md) {
      bottom: -8px;
      height: 35px;
      right: -8px;
      width: 35px;
      &::before {
        height: calc(100% - 17px);
        left: 50%;
        top: 9px;
        width: 1px; }
      &::after {
        height: 1px;
        left: 9px;
        top: 50%;
        width: calc(100% - 17px); } } } }
