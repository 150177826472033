/* -------------------------*/
/* ////// #Navigation //////*/
/* -------------------------*/
body.nav-open {
  overflow: hidden;
  aside, footer, main {
    filter: blur(5px); }
  .header {
    &__bg {
      filter: blur(10px); }
    &__content, .navbar, .logo {
      opacity: 0; } } }
.navMain {
  $n: &;
  &.is-open {
    #{$n} {
      &__inner {
        z: {
          index: 500; } }
      &__close, &__logo {
        opacity: 1;
        transform: scale(1); }
      &__ul {
        transform: translateY(0);
 }        // display: block
      &__li {
        &--1 {
          transform: translateX(0); } } } }
  &__inner {
    align: {
      items: center; }
    background: rgba($color-purpleDk, .875);
    // filter: saturate(15)
    display: flex;
    height: 100vh;
    justify: {
      content: center; }
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z: {
      index: -1; } }
  &__logo.logo {
    left: 0;
    max: {
      width: 60vw; }
    opacity: 0;
    position: fixed;
    top: 0;
    transform: scale(.9); }
  &__close {
    @include vp-relative-prop("right", 34px);
    @include vp-relative-prop("top", 34px);
    opacity: 0;
    position: fixed;
    transform: scale(1.5);
    transition: opacity 400ms ease, transform 800ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    &__btn {
      background: transparent;
      border: 0;
      cursor: pointer;
      outline: 0; } }
  &__ul {
    // display: none
    position: relative;
    z: {
      index: 999; }
    // transform: translateY(50%)
    transition: 400ms ease; }
  &__drop {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%); }
  &__li {
    a {
      color: $color-white;
      font: {
        family: $font-style; }
      text: {
        decoration: none;
        transform: uppercase; }
      padding: {
        bottom: 16px; }
      &:hover, &:focus {
        color: $color-purpleBt; } }
    &--1 {
      font: {
        size: em(20); }
      position: relative;
      transform: translateX(-50%);
      transition: 350ms ease;
      transition-delay: 30ms;
      &:nth-of-type(2) {
        transition-delay: 50ms; }
      &:nth-of-type(3) {
        transition-delay: 70ms; }
      &:nth-of-type(4) {
        transition-delay: 90ms; }
      &:nth-of-type(5) {
        transition-delay: 70ms; }
      &:nth-of-type(6) {
        transition-delay: 50ms; }
      &:nth-of-type(7) {
        transition-delay: 30ms; }
      // @for $i from 1 through 4
      //   &:nth-of-type(#{$i})
      //     transition-delay: 18ms * $i
      @include mq($from: phab, $until: sm) {
        font: {
          size: em(24); } }
      @include mq($from: sm) {
        font: {
          size: em(40); } }
      > a {
        align: {
          items: center; }
        border: {
          bottom: 1px solid $color-purpleBt; }
        display: flex;
        justify: {
          content: space-between; }
        margin: {
          bottom: 16px; }
        .icon {
          height: 14px;
          width: 12px; } }
      &.is-last {
        > a {
          border: 0;
          padding: 0; } } }
    &--2 {
      font: {
        size: em(17, 20); }
      opacity: 0;
      transform: translateY(-40px);
      transition: transform 400ms, opacity 300ms;
      @for $i from 1 through 8 {
        &:nth-of-type(#{$i}) {
          transition-delay: 80ms * $i; } }
      @include mq($from: phab, $until: sm) {
        font: {
          size: em(20, 24); } }
      @include mq($from: sm) {
        font: {
          size: em(30, 40); } }
      a {
        padding: {
          left: 26px; }
        display: none; } }
    &--parent {
      &.is-active {
        color: $color-purpleBt;
        #{$n}__li {
          &--2 {
            opacity: 1;
            transform: translateY(0);
            a {
              display: block; } } } } } } }

.navFooter {
  a {
    color: $color-gray;
    font: {
      size: em(20); }
    text: {
      decoration: none; }
    &:hover {
      color: $color-purpleBt; } }
  &__ul {
    @include mq($until: lg) {
      margin: {
        bottom: calc(2.4vw + 12px); } } }
  &__li {
    margin: {
      bottom: 10px; }
    &--heading {
      margin: {
        bottom: 0; }
      > a {
        font: {
          weight: 800; }
        text: {
          transform: uppercase; }
        display: block;
        margin: {
          bottom: calc(2.4vw + 4px); }
        padding: {
          bottom: 2.4vw; }
        position: relative;
        @include mq($from: md) {
          margin: {
            bottom: 1em; }
          padding: {
            bottom: em(15, 20); } }
        &::after {
          // @include vp-relative-prop("width", 177px)
          content: "";
          background: {
            color: $color-purpleBt; }
          bottom: 0;
          height: 1px;
          left: 0;
          max: {
            width: 100%; }
          position: absolute;
          width: 60%;
          @include mq($from: md, $until: xl) {
            width: 17.7vw; }
          @include mq($from: xl) {
            width: em(177); } } }
      &.is-last {
        > a {
          &::after {
            content: none; } } } } } }
.navSocial {
  &__ul {
    align: {
      items: flex-end; }
    display: flex;
    flex: {
      direction: row; } }
  &__li {
    margin: {
      right: 13px; } }
  &__link {
    color: $color-gray;
    display: block;
    padding: 5px;
    &:hover {
      color: $color-purple; }
    &--light {
      color: $color-purple;
      &:hover {
        color: $color-purpleFd; } }
    .icon {
      display: block; } } }

.box .navSocial__link--light:hover {
  color: $color-purpleFd; }
