/* ---------------------------*/
/* ////// #Testimonials //////*/
/* ---------------------------*/

.test {
  @include vp-relative-prop("padding-bottom", 40px);
  @include vp-relative-prop("padding-top", 70px);
  color: $color-white;
  background: {
    color: $color-purpleDk;
    image: url(/assets/images/bg-testimonials.jpg);
    position: top center;
    repeat: no-repeat;
    size: cover; }
  position: relative;
  &:after {
    @include vp-relative-prop("height", 255px);
    content: "";
    background: linear-gradient(to top, rgba($color-purpleDk, 1) 0%,rgba($color-purpleDk, 1) 9%,rgba($color-purpleDk, 0) 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%; }
  &__title {
    font: {
      family: $font-style; }
    text: {
      align: center;
      transform: uppercase; }
    p {
      @include fluidType(20px, 30px);
      line: {
        height: 1em; }
      padding: {
        bottom: 0; } }
    h2 {
      @include fluidType(44px, 80px);
      @include vp-relative-prop("padding-bottom", 64px);
      color: $color-white;
      font: {
        weight: 800; }
      line: {
        height: .875em; } } }
  &__wrap {
    position: relative;
    z: {
      index: 1; }
    @include mq($from: phab) {
      &::before, &::after {
        content: "";
        background: {
          color: $color-purpleBt; }
        height: calc(100% - 5em);
        position: absolute;
        top: 0;
        width: 1px; }
      &::before {
        left: 16.67%; }
      &::after {
        right: 16.67%; } }
    @include mq($from: md) {
      &::before {
        left: 25%; }
      &::after {
        right: 25%; } } }
  &__inner {
    @include vp-relative-prop("padding-left", 40px);
    @include vp-relative-prop("padding-right", 40px);
    display: flex;
    flex: {
      direction: column; }
    height: calc(100% - 5em);
    @include mq($from: xl) {
      height: 100%; } }
  &__content {
    transition: all 250ms ease; }
  &__name {
    @include fluidType(22px, 34px);
    font: {
      weight: 800; }
    line: {
      height: 1em; }
    padding: {
      bottom: 0; }
    transition: all 350ms ease; }
  &__posit {
    @include fluidType(18px, 26px);
    font: {
      weight: 800; }
    line: {
      height: 1em; }
    padding: {
      bottom: .688em; }
    transition: all 350ms ease; }
  &__logo {
    margin: {
      bottom: em(38);
      left: auto;
      right: auto;
      top: auto; }
    padding: {
      top: em(80); }
    transition: all 350ms ease; }
  &__link {
    align: {
      self: center; }
    margin: {
      bottom: .5em; }
    transition: all 200ms ease; }
  &__nav {
    @include vp-relative-prop("bottom", 76px);
    // @include vp-relative-prop("padding-left", 40px)
    // @include vp-relative-prop("padding-right", 40px)
    display: flex;
    justify: {
      content: space-between; }
    margin: auto;
    @include mq($until: md) {
      padding: {
        left: calc(1vw + 5px);
        right: calc(1vw + 5px); } }
    @include mq($from: md, $until: xl) {
      padding: {
        left: 5px;
        right: 5px; } }
    @include mq($from: md) {
      left: 50%;
      position: absolute;
      width: 50%;
      z: {
        index: 1; }
      transform: translateX(-50%); }
    @include mq($from: xl) {
      padding: {
        left: em(40);
        right: em(40); } } } }
.test {
  &__li {
    opacity: .14;
    &--active {
      opacity: 1; }
    &.is-animating {
      &--in {
        .test__content, .test__name, .test__posit {
          transform: scale(1.25, 1.15); }
        .test__logo {
          transform: scale(.75); }
        .test__link {
          opacity: 0;
          transform: translateY(-20px); } }
      &--out {
        .test__content, .test__name, .test__posit {
          opacity: .14;
          transform: scale(.688, .875); }
        .test__logo {
          opacity: .14;
          transform: scale(.75); }
        .test__link {
          opacity: 0;
          transform: translateY(20px); } } } } }
