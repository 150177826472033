/* ---------------------*/
/* ////// #Header //////*/
/* ---------------------*/

.header {
  background: {
    color: $color-purpleDk; }
  position: relative;
  z: {
    index: 100; }
  &__bg {
    background: {
      position: center center;
      repeat: no-repeat;
      size: cover; }
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity 450ms cubic-bezier(0.600, 0.040, 0.980, 0.335);
    @at-root .has-js & {
      opacity: 0; }
    &.is-sliding {
      opacity: 0; }
    &.is-visible {
      opacity: 1; } }
  &__front {
    @include vp-relative-prop('padding-bottom', 130px);
    justify: {
      content: flex-end; }
    position: relative; }
  &__content {
    @include mq($until: sm) {
      padding: 0; } }
  &--main {
    height: 60vh;
    min: {
      height: 60vh; }
    @include mq($from: md) {
      height: 100vh;
      min: {
        height: 100vh; } }
    &__bg {
      background: {
        position: center center;
        repeat: no-repeat;
        size: cover; }
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: opacity 800ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 100ms;
      @at-root .has-js & {
        opacity: 0;
        &.is-active {
          opacity: 1;
          transition: opacity 1000ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 100ms; } } } }
  &--sub {
    min-height: 60vh;
    &__content {
      // @include vp-relative-prop('padding-top', 130px)
      align: {
        items: flex-start; }
      display: flex;
      justify: {
        content: flex-end; }
      @include mq($until: sm) {
        padding: 0; } } } }

.logo {
  @include vp-relative-prop("padding-left", 15px);
  @include vp-relative-prop("padding-top", 25px);
  display: inline-block;
  padding: {
    bottom: 1em; }
  position: relative;
  z: {
    index: 999; }
  transition: opacity 1000ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 100ms;
  &::after {
    content: "";
    background: {
      color: $color-purpleBt; }
    bottom: -1px;
    height: 1px;
    left: -5px;
    // opacity: 0
    position: absolute;
    width: calc(100% + 5px);
    transition: width 300ms 50ms;
 }    // animation: test 8000ms forwards
  &__link {
    display: block;
    padding: 10px; } }

.navbar {
  // @include vp-relative-prop("right", 34px)
  @include vp-relative-prop('padding-bottom', 105px);
  // @include vp-relative-prop("padding-top", 34px)
  align: {
    items: center; }
  color: $color-white;
  display: flex;
  flex: {
    direction: column; }
  height: 100%;
  justify: {
    content: space-between; }
  position: absolute;
  // right: 0
  top: 0;
  width: 71px;
  z: {
    index: 1; }
  transition: opacity 350ms ease;
  @include mq($until: sm) {
    right: 3px;
    width: em(55); }
  @include mq($from: sm, $until: xl) {
    right: calc(3.4vw + 3px); }
  @include mq($from: xl) {
    right: 39px; }
  &__icon {
    // @include vp-relative-prop("right", 34px)
    // @include vp-relative-prop("top", 34px)
    padding: {
      left: 5px;
      right: 5px; }
    position: fixed;
    top: 0;
    z: {
      index: 100; }
    transition: all 350ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    @include mq($until: sm) {
      right: 5px; }
    @include mq($from: sm, $until: xl) {
      right: 3.4vw; }
    @include mq($from: xl) {
      right: 34px; }
    &.is-stuck {
      background: {
        color: $color-purple; }
      transform: translateY(0); }
    @include mq($until: sm) {
      transform: translateY(16px); }
    @include mq($from: sm) {
      transform: translateY(34px); } }
  &__btn {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: 0;
    padding: 0;
    @include mq($until: sm) {
      font: {
        size: 10px; } } }
  &__vert {
    // @include vp-relative-prop("margin-top", 68px)
    background: {
      color: $color-purpleBt; }
    flex: auto;
    margin: {
      top: em(68); }
    width: 1px;
    @include mq($from: sm) {
      margin: {
        top: em(103); } }
    @at-root .no-js & {
      max: {
        height: 100%; } } } }

#keyart-line {
  left: 0;
  position: absolute;
  top: 0;
  z: {
    index: 100; }
  polyline {
    stroke: $color-purpleBt;
    stroke-width: 2px;
 } }    // stroke-dashoffset: 1500px


