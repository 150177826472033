$browser-context: 16;

//text glow
@mixin textGlow($px, $color, $spread: $px){

  text-shadow: $px $px $spread $color,
    (-$px) $px $spread $color,
    $px (-$px) $spread $color,
    (-$px) (-$px) $spread $color

}

// em() calculations from pixels
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

/*------VW------*/

//get viewport width
@function get-vw($target) { 
  $vw-context: (1000*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

// relative units if smaller than hd
@mixin vp-relative-prop($property, $size) {
  #{$property}: get-vw($size);
  @media screen and (min-width: 1220px) {
    #{$property}: $size;
  }
}

// Strips values of their units.
// See: https://css-tricks.com/snippets/sass/strip-unit-function/
@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

// You can replace `@media` with whatever media query mixin you're using.
@mixin fluid(
  $min-value,
  $max-value,
  $min-vw,
  $max-vw,
  $properties...
) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media only screen and (min-width: #{$min-vw}) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{stripUnit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{stripUnit($max-vw - $min-vw)});
    }
  }

  @media only screen and (min-width: #{$max-vw}) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

/*------fluid typography------*/

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluidType($min-font-size, $max-font-size, $min-vw: 320px, $max-vw: 1200px) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{stripUnit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{stripUnit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// color mapping
@function color($color,$tone){

  @warn map-get($palettes,$color);

  @if map-has-key($palettes,$color){
      $color: map-get($palettes,$color);

          @if map-has-key($color,$tone){
              $tone: map-get($color,$tone);
              @return unquote($tone);
          }

          @warn "unknown tone `#{$tone}` in color";
          @return null;        
  }

  @warn "unknown color `#{$color}` in palette";
  @return null;
 
}
// example usage
// $palettes: (
//     blue: (
//         base:    #23778G,
//         light:   #23778G,
//         lighter: #48525C
//     ),
//     yellow: (
//         base:   #ffa949,
//         light:  #eda2fs
//     )
// );

// .banner{
//   background-color: color(blue,base);
//  }
