//* -------------------*//
//* ////// #Vars //////*//
//* -------------------*//

/*------#breakpoints------*/

// $breakpoints: (
//   'mobile': 320px,
//   'phablet': 480px,
//   'six50': 650px,
//   'nine60': 960px,
//   'tablet': 768px,
//   'desktop': 1024px,
//   'hd': 1220px 
// );

$breakpoints: (
  xs   : 0,
  phab : 480px,
  sm   : 544px,
  md   : 768px,
  mdlg : 820px,
  lg   : 992px,
  xl   : 1200px
);

$mq-breakpoints: (
  xs   : 0,
  phab : 480px,
  sm   : 544px,
  md   : 768px,
  mdlg : 820px,
  lg   : 992px,
  xl   : 1200px
);

/*------#colors------*/

$color-purple  : #553670;
$color-purpleM : #5b5478;
$color-purpleBt: #883ec8;
$color-purpleFd: #b2a6bc;
$color-purpleLt: #e3e1e6;
$color-purpleDk: #291a45;

$color-gray    : #828183;
$color-grayDk  : #202021;
$color-grayMd  : #343334;
$color-grayMdLt: #b7b5b9;
$color-grayLt  : #e3e1e6;
$color-grayLtr : #efeeee;

$color-black   : #1d191a;
$color-white   : #ffffff;


/*------#fonts------*/

$font-base  : 'DDin', Arial, sans-serif;
$font-style : 'DDinCondensed', Impact, sans-serif;


/*------#flexbox-grid------*/
// $grid-columns: 15; // Set number of columns in the grid
// $gutter: 10px; // Set gutter size
// $content-well-max-width: 1200px; // Set the max-width of the content well
// $columns: 15;
// The sizing of the gutter.
$gutter : (
  height: 0,
  width: 20px
);