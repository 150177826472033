/* -------------------*/
/* ////// #Base //////*/
/* -------------------*/

/*------Load Fonts------*/
@include font-face("DDin", /assets/fonts/d-din, normal, normal, woff2 woff);
@include font-face("DDin", /assets/fonts/d-din-bold, 800, normal, woff2 woff);
@include font-face("DDin", /assets/fonts/d-din-italic, normal, italic, woff2 woff);
@include font-face("DDinCondensed", /assets/fonts/d-dincondensed, normal, normal, woff2 woff);
@include font-face("DDinCondensed", /assets/fonts/d-dincondensed-bold, 800, normal, woff2 woff);

/*------Base Styles------*/
* {
  box-sizing: border-box;
  &:focus {
    box: {
      shadow: 0 0 2px 2px rgba($color-purpleBt, .388); }
    outline: 0; } }

body {
  color: $color-grayDk;
  font: {
    display: swap;
    family: $font-base; } }

//   &.is-locked
//     overflow: hidden
hr {
  border: {
    bottom-style: solid;
    color: $color-grayLt;
    style: initial;
    width: 1px; }
  outline: 0; }

img, iframe {
  max: {
    width: 100%; } }

img {
  pointer: {
    events: none; }
  vertical: {
    align: middle; } }

.grid {
  // extend grid beyond 1200px
  width: 100%;
  &--outer {
    margin: 0 auto;
    max: {
      width: 1400px; }
    // padding:
    //   left: 2vw
    //   right: calc(2vw + 22px)
    width: 100%; } }
.row {
  &--xs {
    &-between {
      justify: {
        content: space-between; } } } }

.secondary {
  @include vp-relative-prop("margin-top", 40px); }
