/* ---------------------------*/
/* ////// #Main Content //////*/
/* ---------------------------*/

.content {
  @include vp-relative-prop('padding-bottom', 78px);
  @include vp-relative-prop('padding-top', 94px);
  background: {
    color: $color-grayLtr; } }

.bg {
  &--white {
    background: {
      color: $color-white; } } }

.box {
  @include mq($from: md, $until: xl) {
    padding: {
      left: 2.4vw; } }
  @include mq($from: xl) {
    padding: {
      left: em(34); } }
  &__inner {
    @include vp-relative-prop('padding-bottom', 24px);
    @include vp-relative-prop('padding-left', 27px);
    @include vp-relative-prop('padding-right', 27px);
    @include vp-relative-prop('padding-top', 24px);
    background: {
      color: $color-white; }
    @include mq($until: sm) {
      margin: {
        bottom: calc(2.4vw + 10px);
        top: calc(2.4vw + 10px); } } }
  &__ul {
    line: {
      height: 1.125em; }
    list: {
      style: {
        type: none; } } }
  &__li {
    margin: {
      bottom: .388em;
      top: .5em; }
    // &:last-of-type
    //   margin:
 }    //     bottom: 0
  a {
    &:hover {
      color: $color-purpleDk; } }
  // &__li
  //   margin:
  //     left: 1.125em
  .imgplus {
    @include mq($until: md) {
      margin: {
        bottom: calc(2vw + 5px); } } } }

.card {
  &--sm {
    $sm: &;
    &:first-child {
      #{$sm}__inner {
        margin-left: 0; } }
    &:last-child {
      #{$sm}__inner {
        margin-right: 0; } }
    &__inner {
      // @include vp-relative-prop('margin-left', 24px)
      // @include vp-relative-prop('margin-right', 24px)
      // @include vp-relative-prop('padding-right', 24px)
      padding: {
        right: calc(2.4vw + 5px); }
      @include mq($from: md, $until: lg) {
        margin: {
          left: 2.4vw;
          right: 2.4vw; } }
      @include mq($from: lg) {
        margin: {
          left: em(24);
          right: em(24); } }
      @include mq($from: xl) {
        padding: {
          right: em(24); } } }
    // &__img
 }    //   @include vp-relative-prop('margin-bottom', 24px)
  &--lg {
    &__inner {
      @include vp-relative-prop('padding-right', 34px); }
    &__img {
      @include mq($until: sm) {
        margin: {
          bottom: 2.4vw; } } } }
  &__inner {
    @include vp-relative-prop('margin-top', 60px);
    // @include vp-relative-prop('padding-bottom', 20px)
    // @include vp-relative-prop('padding-left', 24px)
    // @include vp-relative-prop('padding-top', 20px)
    background: {
      color: $color-white; }
    padding: {
      bottom: 2.4vw;
      left: calc(2.4vw + 5px);
      right: calc(2.4vw + 5px);
      top: 2.4vw; }
    @include mq($from: xl) {
      padding: {
        bottom: em(20);
        left: em(24);
        top: em(20); } }
    > div > .col {
      padding: {
        bottom: 5px;
        top: 5px; }
      @include mq($from: sm) {
        padding: {
          bottom: 10px;
          top: 10px; } } } }
  &__content {
    h2 {
      border: {
        bottom: 0; }
      padding: {
        bottom: 0; } }
    h3 {
      @include vp-relative-prop('padding-bottom', 10px);
      padding: {
        top: 0; } }
    hr {
      @include vp-relative-prop('margin-bottom', 20px); } }
  &__img {
    .img {
      margin: 0; } } }

.proj {
  $p: &;
  @include vp-relative-prop("margin-top", 3px);
  text: {
    align: center; }
  transition: height 1000ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
  @include mq($until: phab) {
    justify: {
      content: center; } }
  &__row {
    @include mq($until: md) {
      padding: {
        bottom: 1.5em; } } }
  &__li {
    &.is-active {
      max: {
        width: em(300); }
      transform: scale(1);
      transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 300ms, max-width 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955); }
    &.not-active {
      max: {
        width: 0; }
      transform: scale(0);
      transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955), max-width 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 200ms; } }
  &__inner {
    // @include vp-relative-prop("padding", 17px)
    padding: calc(1vw + 5px);
    @include mq($from: xl) {
      padding: em(17); }
    &--wide {
      @include mq($until: phab) {
        padding: 0; } } }
  &__wrap {
    align: {
      items: center; }
    display: flex;
    flex: {
      direction: column; }
    // height: 100%
    height: auto;
    justify: {
      content: center; }
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z: {
      index: 1; }
    transform: translateY(-50%); }
  &__link {
    // display: inline-block
    // position: relative
    // &::after
    //   content: ""
    //   background: $color-purpleBt
    //   bottom: -8px
    //   height: 24px
    //   position: absolute
    //   right: -8px
    //   width: 24px
    //   @include mq($from: md)
    //     bottom: -8px
    //     height: 34px
    //     right: -8px
    //     width: 34px
    &:hover {
      #{$p}__hover {
        opacity: 1; }
      #{$p}__heading {
        transform: translateY(-28px); }
      #{$p}__title {
        opacity: 1;
        transform: translateY(0);
        &::before, &::after {
          opacity: 1;
          transform: translate(0, 0); } }
      #{$p}__open {
        opacity: 1;
        transform: translateY(0);
        // transform: translateY(-1em)
        // @include mq($from: lg)
 } } }        //   transform: translateY(0)
  // &__plus
  //   bottom: em(-8)
  //   height: 25px
  //   position: absolute
  //   right: em(-8)
  //   width: 25px
  //   &::before, &::after
  //     content: ""
  //     background: $color-white
  //     position: absolute
  //     z:
  //       index: 1
  //   &::before
  //     height: calc(100% - 13px)
  //     left: 50%
  //     top: 7px
  //     width: 1px
  //   &::after
  //     height: 1px
  //     left: 7px
  //     top: 50%
  //     width: calc(100% - 13px)
  //   @include mq($from: md)
  //     bottom: -8px
  //     height: 35px
  //     right: -8px
  //     width: 35px
  //     &::before
  //       height: calc(100% - 17px)
  //       left: 50%
  //       top: 9px
  //       width: 1px
  //     &::after
  //       height: 1px
  //       left: 9px
  //       top: 50%
  //       width: calc(100% - 17px)
  &__hover {
    color: $color-white;
    text: {
      transform: uppercase; }
    align: {
      content: flex-end; }
    background: {
      blend: {
        mode: multiply; }
      color: $color-purpleM; }
    display: flex;
    flex: {
      wrap: wrap; }
    height: 100%;
    justify: {
      content: center; }
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity 1200ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    @media (hover: hover) {
      opacity: 0; } }
  &__heading {
    // @include vp-relative-prop("padding-bottom", 22px)
    // @include vp-relative-prop("margin-left", 64px)
    // @include vp-relative-prop("margin-right", 64px)
    // @include vp-relative-prop("padding-top", 22px)
    // @include fluidType(19px, 40px)
    color: $color-white;
    font: {
      size: em(30);
      weight: 800; }
    align: {
      self: stretch; }
    flex: 0;
    padding: {
      bottom: .3em;
      top: .388em; }
    position: relative;
    transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 300ms;
    @media(hover: none) {
      transform: translateY(-28px); }
    @include mq($from: sm, $until: md) {
      font: {
        size: em(26); }
      // padding:
      //   bottom: 14px
 }      //   top: 14px
    @include mq($from: md, $until: lg) {
      font: {
        size: em(30); } }
    @include mq($from: lg) {
      font: {
        size: em(40); }
      padding: {
        bottom: 22px;
        top: 22px; } }
    &::before, &::after {
      content: "";
      background: $color-purpleBt;
      height: 1px;
      left: .75em;
      // opacity: 0
      position: absolute;
      width: calc(100% - 1.5em);
      @include mq($from: lg) {
        left: 1.5em;
        width: calc(100% - 3em); }
 }      // transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 300ms, opacity 300ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 200ms
    &::before {
      top: 0;
 }      // transform: translateX(16px)
    &::after {
      bottom: 0;
 } }      // transform: translateX(-16px)
  &__title {
    @include fluidType(17px, 24px);
    // @include vp-relative-prop("margin", 20px)
    color: $color-white;
    font: {
      family: $font-style; }
    align: {
      self: stretch; }
    flex: 0 0 100%;
    margin: 5px 16px;
    padding: {
      bottom: em(10, 24);
      top: em(10, 24); }
    position: relative;
    &::before, &::after {
      content: "";
      background: $color-purpleBt;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%; }
    &::before {
      top: 1px; }
    &::after {
      bottom: 1px; }
    @media (hover: hover) {
      opacity: 0;
      transform: translateY(14px);
      transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 100ms, opacity 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
      &::before, &::after {
        opacity: 0;
        transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 300ms, opacity 300ms cubic-bezier(0.230, 1.000, 0.320, 1.000) 200ms; }
      &::before {
        transform: translateX(16px); }
      &::after {
        transform: translateX(-16px); } }
    @include mq($from: xl) {
      margin: 20px; } }
  &__desc {
    padding: {
      top: em(24); }
    p {
      &:first-of-type {
        font: {
          size: em(18); }
        text: {
          align: left; } } } }
  &__open {
    @include fluidType(17px, 20px);
    // margin:
    //   top: em(14)
    padding: {
      // bottom: em(10, 20)
      top: em(10, 20); }
    // position: absolute
    // top: 100%
    .icon {
      top: calc(50% - .3125em); }
    @media (hover: hover) {
      opacity: 0;
      transform: translateY(12px);
      transition: transform 300ms cubic-bezier(0.455, 0.030, 0.515, 0.955) 200ms, opacity 350ms cubic-bezier(0.455, 0.030, 0.515, 0.955); } }
  &--lg {
    @include vp-relative-prop("margin-top", 40px);
    &__li {
      @include mq($from: xl) {
        max: {
          width: 420px; } } }
    // &__inner
    //// padding: 0
    &__open {
      position: absolute;
      top: 2em;
      // padding:
 } }      //   bottom: em(50, 20)
  &--pg {
    justify-content: center;
    // &__li
    //   max:
    //     width: 25%
    //   @include mq($until: xl)
 } }    //     flex: 1 1 25%

.projOutter {
  @include vp-relative-prop("padding-bottom", 68px);
  @include vp-relative-prop("padding-top", 24px); }

.projNav {
  // @include vp-relative-prop("margin-top", 32px)
  // @include vp-relative-prop("padding-left", 50px)
  // @include vp-relative-prop("padding-right", 50px)
  display: flex;
  @include mq($until: sm) {
    flex: {
      wrap: wrap; }
    justify: {
      content: space-around; }
    margin: {
      bottom: 3.2vw; } }
  @include mq($from: sm) {
    margin: {
      top: 3.2vw; }
    flex: {
      direction: column; } }
  @include mq($from: lg, $until: xl) {
    padding: {
      left: 5vw;
      right: 5vw; } }
  @include mq($from: xl) {
    margin: {
      top: em(32); }
    padding: {
      left: em(50);
      right: em(50); } }
  &__li {
    position: relative;
    &::after {
      content: "";
      background: {
        color: $color-grayLt; }
      bottom: 0;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%; }
    @include mq($until: sm) {
      margin: {
        left: 5px;
        right: 5px; } }
    @include mq($from: sm) {
      &:last-of-type {
        &::after {
          content: none; } } } }
  &__link {
    @include vp-relative-prop("padding-bottom", 28px);
    @include vp-relative-prop("padding-top", 28px);
    @include fluidType(20px, 56px);
    color: $color-grayMdLt;
    cursor: pointer;
    font: {
      family: $font-style; }
    text: {
      decoration: none;
      transform: uppercase; }
    display: block;
    transition: color 350ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
    &.is-active {
      color: $color-purple; } } }

.fav {
  // @include vp-relative-prop("padding-bottom", 120px)
  // @include vp-relative-prop("padding-top", 120px)
  background: {
    image: url(/assets/images/bg-favorite-projects.jpg);
    position: center center;
    repeat: no-repeat;
    size: cover; }
  padding: {
    bottom: 8vw;
    top: 8vw; }
  @include mq($from: xl) {
      padding: {
        bottom: em(120);
        top: em(120); } }
  &__text {
    @include mq($until: lg) {
      margin: {
        bottom: calc(2.4vw + 5px); } }
    @include mq($from: sm, $until: lg) {
      flex: {
        direction: row; }
      padding: {
        left: 6vw; } } }
  &__title {
    align: {
      self: flex-start; }
    background: {
      color: $color-white; }
    margin: {
      bottom: 2.4vw; }
    padding: 2.4vw;
    @include mq($from: lg, $until: xl) {
      margin: {
        bottom: 4.6vw; } }
    @include mq($from: xl) {
      margin: {
        bottom: em(46, 60); }
      padding: em(38, 60); } }
  &__btn {
    align: {
      self: center; }
    @include mq($until: lg) {
      margin: {
        left: 2.4vw; } } } }

