/* -------------------------*/
/* ////// #Typography //////*/
/* -------------------------*/

.title, .slide__title {
  @include textGlow(6px, rgba($color-black, .4));
  color: $color-white; }

.title, .slide__title, .fav__title {
  @include fluidType(34px, 60px);
  @include vp-relative-prop("padding-bottom", 36px);
  font: {
    family: $font-style; }
  line: {
    height: .875em; }
  text: {
    transform: uppercase; }
  span {
    @include fluidType(20px, 40px);
    display: inline-block; }
  strong {
    @include fluidType(40px, 120px);
    line: {
      height: .75em; }
    display: block; } }

h2 {
  @include fluidType(30px, 56px);
  @include vp-relative-prop("padding-bottom", 20px);
  color: $color-purple;
  font: {
    family: $font-style; }
  line: {
    height: .875em; }
  text: {
    transform: uppercase; }
  .text--smaller {
    @include fluidType(20px, 30px);
    font: {
      weight: 400; }
    display: block;
    line: {
      height: 1em; } }
  @at-root .secondary & {
    @include fluidType(24px, 30px);
    line: {
      height: 1em; }
    text: {
      align: center; }
    strong {
      @include fluidType(40px, 80px);
      line: {
        height: .875em; } } }
  @at-root .wysiwyg & {
    border: {
      bottom: 1px solid $color-grayLt; }
    margin: {
      bottom: 8px; } }
  @at-root .wysiwyg.two-col & {
    display: inline-block; } }
h3 {
  @include fluidType(24px, 38px);
  @include vp-relative-prop("padding-bottom", 48px);
  @include vp-relative-prop("padding-top", 12px);
  color: $color-gray;
  font: {
    family: $font-style; }
  text: {
    transform: uppercase; } }

p {
  @include fluidType(16px, 20px);
  @include vp-relative-prop("padding-bottom", 28px);
  line-height: 1.388em;
  &:last-child {
    padding: {
      bottom: 0; } } }

a {
  color: $color-purpleBt;
  transition: 350ms;
  // &:hover
 }  //   transition: 350ms

strong {
  font: {
    weight: 800; } }

em {
  font: {
    style: italic; } }

.text {
  &--fLeft {
    float: left; }
  &.group {
    margin: {
      left: -28px; } }
  &--fRight {
    float: right;
    // &.group
    //   margin:
 }    //     right: -28px
  &--colorPurple {
    color: $color-purple; }
  &--upper {
    text: {
      transform: uppercase; } }
  &--sm-bold {
    @include fluidType(19px, 24px);
    color: $color-black;
    font: {
      family: $font-base;
      weight: 800; }
    text: {
      transform: none; }
    padding: {
      bottom: em(6, 24); } } }

.wysiwyg {
  ol, ul {
    @include fluidType(16px, 20px);
    @include vp-relative-prop("padding-bottom", 28px);
    line-height: 1.388em;
    &:last-child {
      padding: {
        bottom: 0; } }
    li {
      margin: {
        left: 1.125em; } } }
  ol {
    list-style-type: decimal; }
  ul {
    list-style-type: none;
    li {
      position: relative;
      &::before {
        content: "\25A0";
        color: $color-purple;
        font: {
          size: .75em; }
        left: -1.25em;
        position: absolute;
        top: -2px; } } }
  a {
    &:hover {
      color: $color-purpleDk; } } }
