/* ---------------------*/
/* ////// #Footer //////*/
/* ---------------------*/

.careers {
  // @include vp-relative-prop("padding-bottom", 90px)
  // @include vp-relative-prop("padding-top", 90px)
  background: $color-purpleDk;
  margin: {
    top: 0; }
  position: relative;
  &__content {
    align: {
      items: center; }
    display: flex;
    height: 100%;
    top: 0;
    width: 100%;
    z: {
      index: 1; }
    @include mq($until: sm) {
      justify: {
        content: center; } }
    @include mq($from: sm) {
      position: absolute; } }
  &__inner {
    // @include vp-relative-prop("padding-bottom", 50px)
    // @include vp-relative-prop("padding-left", 140px)
    // @include vp-relative-prop("padding-right", 124px)
    // @include vp-relative-prop("padding-top", 50px)
    background: $color-white;
    // width: 56%
    @include mq($from: xl) {
      padding: em(50) em(124) em(50) em(140); }
    @include mq($from: md, $until: xl) {
      padding: 5vw 10vw 5vw 11.4vw; }
    @include mq($until: md) {
      padding: 5vw 5vw 5vw 6vw; }
    @include mq($from: phab) {
      max: {
        width: 80%; } } }
  &__bg {
    text: {
      align: right; } }
  h2 {
    text: {
      align: left; } } }
.footer {
  @include vp-relative-prop("padding-bottom", 100px);
  @include vp-relative-prop("padding-top", 45px);
  background: {
    color: $color-grayDk; }

  // @include mq(mobile, desktop)
  //   background:
 }  //     color: $color-purpleLt
